body.light-login {
  // background: url("../../images/background01.jpg") repeat scroll 0 0;
}

.dz-progress {
  display: none;
}

.drag-drop {
  width: 100%;
  min-height: 170px;
  border-radius: 5px;
  border: 2px dashed #0087F7;
  display: flex;
  flex-wrap: wrap;
  padding: 0 60px;
}

.drag-drop.droppable-hover {
  border: 2px dashed #ffa500;
}

.drag-drop .droppable-label {
  text-align: center;
  margin: auto;
  font-size: 15px;
}

.drag-drop.droppable-hover .droppable-label {
  display: none;
}

.drag-drop .draggable {
  height: 150px;
  width: 150px;
  background-color: #ddd;
  margin: 8px;
  border-radius: 8px;
  vertical-align: top;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
}

.drag-drop .draggable .draggable-label {
  //align-self: flex-end;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.drag-drop .draggable .draggable-label i {
  font-size: 30px;
  margin-bottom: 10px;
}

.draggable-delete {
  align-self: flex-end;
  flex: auto;
  padding: 0 10px 10px 10px;
}

.mce-tooltip {
  display: none;
}

#wizard .content {
  font-size: 15px;
}

.slots-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.slots-detail {
  display: flex;
  flex-wrap: wrap;
}

.slots-detail .slots-availability {
  background-color: #999;
  border-radius: 3px;
  width: 70px;
  height: 70px;
  margin: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.slots-detail .slots-availability.available {
  background-color: #57c16e;
  cursor: pointer;
}

.slots-detail .slots-availability.available.selected {
  background-color: #578cda;
}

.slots-detail .slots-availability .slots-availability-label {
  display: flex;
}

.tr-coloured {
  background-color: lightgreen;
}